const main = async () => {
    const res = await fetch("https://gitlab.com/api/v4/projects/voltages%2Fhayase/repository/commits");
  const json = await res.json();
  const commit = document.getElementById("commits");
  if (commit === null) { 
    console.log("Could not find the element with id: commits")
  }
  else {
    commit.innerText = `${json[0].short_id} - ${json[0].title} \n ${json[1].short_id} - ${json[1].title}`;
  }
}
  
main()
